// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CAu8EW1cT", "HC0xBkTx_", "kIBUQxN3L", "fezfDa4ow", "WjGpN6XGk", "w5xqaK9Dx", "peNsGLDeu", "aplWgL3v8", "pOlpz8SUK"];

const serializationHash = "framer-KkszQ"

const variantClassNames = {aplWgL3v8: "framer-v-11v4qrr", CAu8EW1cT: "framer-v-bomgug", fezfDa4ow: "framer-v-2etmcz", HC0xBkTx_: "framer-v-13tqol3", kIBUQxN3L: "framer-v-tey2gt", peNsGLDeu: "framer-v-s1ndw6", pOlpz8SUK: "framer-v-mhg8d7", w5xqaK9Dx: "framer-v-4ozl4j", WjGpN6XGk: "framer-v-m8gk52"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, fezfDa4ow: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "w5xqaK9Dx", "Image 16:9": "fezfDa4ow", "Image 3:2": "kIBUQxN3L", "Image 4:3": "HC0xBkTx_", "Image 4:5": "WjGpN6XGk", "Variant 7": "peNsGLDeu", "Variant 8": "aplWgL3v8", "Variant 9": "pOlpz8SUK", Default: "CAu8EW1cT"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "CAu8EW1cT", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CAu8EW1cT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-bomgug", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"CAu8EW1cT"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({aplWgL3v8: {"data-framer-name": "Variant 8"}, fezfDa4ow: {"data-framer-name": "Image 16:9"}, HC0xBkTx_: {"data-framer-name": "Image 4:3"}, kIBUQxN3L: {"data-framer-name": "Image 3:2", background: {alt: "", fit: "fill", sizes: "min(255.501px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}}, peNsGLDeu: {"data-framer-name": "Variant 7"}, pOlpz8SUK: {"data-framer-name": "Variant 9"}, w5xqaK9Dx: {"data-framer-name": "Image 1:1"}, WjGpN6XGk: {"data-framer-name": "Image 4:5"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-KkszQ[data-border=\"true\"]::after, .framer-KkszQ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KkszQ.framer-5f8xn2, .framer-KkszQ .framer-5f8xn2 { display: block; }", ".framer-KkszQ.framer-bomgug { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-KkszQ.framer-v-13tqol3.framer-bomgug { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-KkszQ.framer-v-tey2gt.framer-bomgug { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-KkszQ.framer-v-2etmcz.framer-bomgug { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-KkszQ.framer-v-m8gk52.framer-bomgug { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-KkszQ.framer-v-4ozl4j.framer-bomgug { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-KkszQ.framer-v-s1ndw6.framer-bomgug { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-KkszQ.framer-v-11v4qrr.framer-bomgug { aspect-ratio: 0.46545454545454545 / 1; height: var(--framer-aspect-ratio-supported, 550px); }", ".framer-KkszQ.framer-v-mhg8d7.framer-bomgug { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HC0xBkTx_":{"layout":["fixed","fixed"]},"kIBUQxN3L":{"layout":["fixed","fixed"]},"fezfDa4ow":{"layout":["fixed","fixed"]},"WjGpN6XGk":{"layout":["fixed","fixed"]},"w5xqaK9Dx":{"layout":["fixed","fixed"]},"peNsGLDeu":{"layout":["fixed","fixed"]},"aplWgL3v8":{"layout":["fixed","fixed"]},"pOlpz8SUK":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framery5ap2Ll8o: React.ComponentType<Props> = withCSS(Component, css, "framer-KkszQ") as typeof Component;
export default Framery5ap2Ll8o;

Framery5ap2Ll8o.displayName = "Image / Aspect Ratio Copy 6";

Framery5ap2Ll8o.defaultProps = {height: 256, width: 256};

addPropertyControls(Framery5ap2Ll8o, {variant: {options: ["CAu8EW1cT", "HC0xBkTx_", "kIBUQxN3L", "fezfDa4ow", "WjGpN6XGk", "w5xqaK9Dx", "peNsGLDeu", "aplWgL3v8", "pOlpz8SUK"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(Framery5ap2Ll8o, [])